.breadcrumbs {
  position: relative;

  .breadcrumbs-container {
    white-space: nowrap;
    overflow-x: scroll;
    /*Hide scrollbar for IE, Edge and Firefox*/
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* Hide scrollbar for Chrome, Safari and Opera */

    margin-top: 1.2rem;
    margin-bottom: 2.4rem;

    @media screen and (min-width: 992px) {
      margin-top: 1.6rem;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    span {
      font-size: 1.2rem;
    }

    li:last-child {
      padding-right: 2rem;
    }
  }

  &-fade {
    width: 2rem;
    height: 100%;
    display: block;
    position: absolute;
    top: 1px;

    &--left {
      background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5));
      left: 0;
    }

    &--right {
      background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5));
      right: 0;
    }
  }
}
